<template>
  <div class="subscription-product d-flex flex-column p-32 mr-16 ml-16 mb-24">
    <div v-if="isPremium" class="mb-16">
      <p class="placeholder-glow mb-0 mb-0">
        <span class="placeholder" style="width: 160px; height: 16px"></span>
      </p>
    </div>

    <div v-if="isPremium">
      <p class="placeholder-glow mb-0">
        <span class="placeholder" style="width: 270px; height: 32px"></span>
      </p>
    </div>

    <div v-else>
      <p class="placeholder-glow mb-0">
        <span class="placeholder" style="width: 200px; height: 24px"></span>
      </p>
    </div>

    <div class="mt-48">
      <p class="placeholder-glow mb-0">
        <span class="placeholder" style="width: 72px; height: 64px"></span>
      </p>
    </div>

    <div class="mt-32 d-flex flex-column">
      <div class="mb-24">
        <p class="placeholder-glow mb-0">
          <span class="placeholder" style="width: 60%; height: 16px"></span>
        </p>
      </div>
      <div class="mb-24">
        <p class="placeholder-glow mb-0">
          <span class="placeholder" style="width: 80%; height: 16px"></span>
        </p>
      </div>
      <div class="mb-24">
        <p class="placeholder-glow mb-0">
          <span class="placeholder" style="width: 70%; height: 16px"></span>
        </p>
      </div>
    </div>
    <div class="justify-content-center d-flex">
      <p class="placeholder-glow mb-0 w-100">
        <a
          href="#"
          tabindex="-1"
          class="btn btn-secondary disabled placeholder w-100"
          aria-hidden="true"
        ></a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isPremium: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.subscription-product {
  background-color: $white;
  border: 1px solid $mid-grey;
  border-radius: 8px;
  width: 359px;
}
</style>
